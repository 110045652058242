import "styles/pages/page-reservation-online.scss";

import React from "react";
import { Link } from "gatsby";

import Layout from "components/Layout";
import Seo from "components/Seo";

import SubpageHeader from "components/SubpageHeader";
import Breadcrumps from "components/Breadcrumps";

const PageReservations = () => {
  const title = "Rezerwacja wizyt";

  return (
    <Layout>
      <Seo title={title} />
      <Breadcrumps data={title} />
      <SubpageHeader title={title} />

      <section className="page-reservation-main">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-4 col-md-6">
              <img
                className="no-m"
                src={require("assets/images/umow-wizyte-Wolomin.jpg").default}
                alt=""
              />
              <p>Poradnia w Wołominie</p>
              <Link
                to="/rezerwacja-wizyt-wolomin/"
                className="button button--green"
              >
                Umów wizytę
              </Link>
            </div>
            <div className="col-xl-4 offset-xl-2 col-md-6">
              <img
                src={require("assets/images/umow-wizyte-Warszawa.jpg").default}
                alt=""
              />
              <p>Poradnia Warszawa Białołęka</p>
              <Link
                to="/rezerwacja-wizyt-bialoleka/"
                className="button button--green"
              >
                Umów wizytę
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PageReservations;
